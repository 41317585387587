.msgContainer{
    margin: 10px 15px;
    width: fit-content;
    max-width: 40%;
    padding: 16px;
    border-radius: 10px;
    background-color: darkcyan;
    color: white;
}

.msgTimeStampAlign{
    display: flex;
    flex-direction: column;
    
    
}

.msgSender{
    margin-left: auto;
    background-color: salmon;
    color: #131930;
}
.removeBottom{
    margin-bottom: -4px;
}

.msgText {
    margin:0px;
}

.msgTimeStamp {
    font-size: .75rem;
    margin: 4px auto;
    opacity: 0.69;
}

.msgDateStamp {
    font-size: .75rem;
    margin: 0px 4px 0px 0px;
    opacity: 0.69;
    

}

.msgTimeStampPosNS {
    display: flex;
    justify-content: start;
    max-width: 100%;
    margin: auto;
    
}

.msgTimeStampPos {
    display: flex;
    justify-content: end;
    margin: auto;
    /* display: none; */
    /* width: fit-content; */
}



