.ctn {
    background-color: #e9e9e9;
    display: flex;
    flex-direction: column;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='60' height='60' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%231677ff' fill-opacity='0.05'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

}

.messageBox {
    width: auto;
    padding: 10px;
    min-height: 46px;
    height: 8%;
    max-height: 55px;
    background-color: #1c658c;
    position: fixed;
    right: 0px;
    bottom: 46px;
    left: 0px;
    display: flex;
    align-items: center;
    align-content: space-between;
}

@media (min-width: 768px) {
    .messageBox {
        width: calc(100% - 512px);
        margin-left:256px;
    }
}

.messageBoxBuffer {
    flex: 1;
    flex-grow: 0;
    flex-basis: 8vh;
    min-height: 46px;
    height: 8%;
    max-height: 55px;
}

.inside {
    width: 100%;
    height: 36px;
    margin-right: 5px;
    background-color: var(--offWhite);
    border-radius: 50px;
    padding-left: 20px;
    padding-right: 20px;
    border: 2px solid var(--offWhite);

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.inside:focus {
    outline: none;
    border: 2px solid var(--offWhite);
}

.inside::placeholder {
    color: #8a8989;
    opacity: 1;
}

.sendButton {
    width: auto;
    margin-right: 17.5px;
    height: 70%;
    color: var(--offWhite);
    transform: rotate(43.5deg);
    cursor: pointer;

}

