.pageCtn {
  background-color: var(--primaryBg);
  /* display: flex; */
  align-items: center;
  flex-direction: column;
}

.ctn {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 5px;
}

.bookingBuffer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 12px;
}

.inputCtn{
  position: relative;
}

.availTime {
  margin: 15px 0px;
}

.break1 {
  display: none;
}

@media only screen and (max-width: 380px) {
  .break1 {
    display: inline;
  }
  /* input:nth-child(3) {
    margin-top: 10px;
  } */
}

.time {
  width: 100%;
  margin: 0px 0px;
  display: block;
  position: absolute;
}
.time::-webkit-slider-runnable-track {
  background: var(--offWhite);
  border-radius: 10px;
}
time::-moz-range-track {
  background: var(--offWhite);
}

.time2::-webkit-slider-runnable-track {
  height: 0px;
}

.sliderCtn {
  width: 90%;
  margin: 0px 5%;
  position: relative;
  margin-bottom: 10px;

}

label {
  margin: 0px 5px;
}

.calAuthLink {
  display: flex;
  flex-direction: column;
}

.calAuthLink > div {
  margin: auto;
}

.bookingCard {
  border-radius: 10px;
  margin-bottom: 3px;
  position: relative;
  max-width: 400px;
  background-color: #ffffff;
}

.topBookingCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 0px 5%;
}

.bookingCheck {
  margin: 0px;
}

.nameTimeCtn > p {
  margin-top: 10px;
  margin-bottom: 5px;
}

.nameTimeCtn {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.timeText {
  margin-right: 24px !important;
}
