.loginCtn {
  position: relative;
  width: fit-content;
  /* border: 1px rgb(114, 114, 114) solid; */
  border-radius: 20px;
  padding: 16px 32px;
  box-shadow: 0px 10px 50px rgb(114, 114, 114);
  background-color: rgb(255, 255, 255);
  /* z-index: 0; */
  height: 400px;
  justify-content: space-between;
}
.loginTitleCtn {
  position: absolute;
  top: -82px;
  z-index: -1;
  width: 289px;
  /* border: 1px rgb(114, 114, 114) solid; */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 16px 32px;
  box-shadow: 0px 10px 50px rgb(114, 114, 114);
  background-color: #1677ff;
}
.backgroundColour {
  background-color: rgb(234, 233, 233);
  /* z-index: -1; */
}

.registerLinkText {
  display: inline;
  margin: 0px;
  text-align: center;
  font-style: italic;
  cursor: pointer;
  color: #1677ff;
}
.forgotPasswordLinkText {
  /* display: b; */
  margin: 0px;
  font-weight: 500;
  text-align: right;
  font-style: italic;
  cursor: pointer;
  color: #fa541c;
  width: 100%;
}
.forgotPasswordLink {
  display: block;
  width: 100%;
}
.labelCtn {
  width: 100%;
  margin: 4px 0px;
}
.forgetPasswordCtn {
  width: 100%;
}
.loginFormIconCtn {
  position: relative;
  width: 100%;
}
.loginFormIcon {
  position: absolute;
  z-index: 0;
  left: -24px;
  top: -8px;
  color: #1677ff;
}
