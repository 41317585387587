.ctn{
  /* background-color: white; */
  /* background-color: #BAA1A7; */
  background-color: lightyellow;
}
.timeGrid {
  display: grid;
  color: var(--offWhite); 
  background-color: #B6C7E6;
  color: white; 
}

.dateGrid {
  width: 100%;
  height: 40px;
  text-align: center;
  background-color: #B6C7E6;
  color: white;
  display: grid;
  position: sticky;
  top:99px;
  z-index:3;
  grid-template-columns: 40px repeat(7, 1fr);
}

.dateItem:nth-child(1) {
  border-style: none;
  background-color: #B6C7E6;
  border-right: 1px dashed white;
  border-bottom: 1px dashed white;
}

.timeItem {
  width: 100%;
  height: 100%;
  border-bottom: 1px dashed white;
  display: flex;
}
.timeText {
  text-align: center;
  margin-top: auto;
  align-self: flex-start;
  width: 100%;
  margin-top: 4px;
  font-size: 14px;
}

.dateCtn {
  width: 100%;
  height: 50px;
  color: var(--textColour);
  background-color: var(--primary);
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: sticky;
  top: 49px;
  z-index: 3;
}


.dateItem {
  border-right: 1px dashed white;
  z-index: 3;
}

.dateItemText {
  margin: 0px;
}

.timeCtn {
  display: flex;
  position: relative;
}

/* .plus {
  z-index: 2;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: absolute;
  right: 32px;
} */

/* .icon {
  color: lightskyblue;
  fill: lightblue;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: fixed;
  bottom: 10%;
} */

.gridBgCtn {
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.gridBgItem {
  /* z-index: 2; */
  border-bottom: 1px dashed black;
  opacity: 10%;
  /* border-left: 1px solid #D3C7E6; */
  /* border-right: 1px solid #D3C7E6; */
  /* border-bottom: 1px dashed var(--grayDark);
  border-left: 1px solid var(--grayDark);
  border-right: 1px solid var(--grayDark); */
}

.navIcon{
  height: 100%;
  width:100%;
  cursor: pointer;
  display: block;
}

.bookingInfoPopup {
  display: flex;
  justify-content: center;
}