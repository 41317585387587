.pageCtn {
  background-color: var(--primaryBg);
}

.ctn {
  width: 90%;
  display: flex;
  flex-direction: column;
  margin: auto;
}


.descriptionBox {
  background-color: var(--grayDark);
  padding: 5px;
  width: 100%;
  color: var(--offWhite);
  border-bottom: none !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top:25px;
  align-self: center;
}
  

.descriptionBottom {
  background-color: var(--offWhite);
  width: 100%;
  min-height:125px;
  margin-bottom: 5px;
  padding: 5px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: none !important;
  resize: none;
  align-self: center;
}

.price {
  width: 100%;
  align-self: center;
  display: flex;
}

.fileCtn{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0px;
  color: var(--textColour);
  padding: 5px;
}

.thumbnail {
  height: 24px;
  width:auto;
  padding: 5px;
}

.name{
  margin: 0px;
  text-align: center;
  font-size: 16px;
  padding: 0px 2px;
  max-width: calc(90vw - 80px);
  /* 68px */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.thumbNameCtn{
  display: flex;
  align-items: center;
}

.filesListCtn{
  width: 100%;
  padding: 0px;
  max-height: 270px;
  overflow-y: scroll;
}

.link {
  all: unset;
}

/* Creator */

.fileDropdownCtn{
  position: absolute;
  top: 40;
  background-color: var(--grayDark);
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--grayDark);
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}