.clientFileContainer {
    /* background-color: var(--offWhite); */
    background-color: var(--primaryBg);
    /* padding: 0px 5%; */
}



.descriptionBox {
  position: relative;
  min-height: 125px;
  height: 100%;
  width: 90%;
  margin: 10px auto;
  border-radius: 10px;
  background-color: var(--primary);
  color: var(--textColour);
  /* border: 2px solid var(--primaryDark); */
  white-space: pre-line;
}

.descriptionText {
  padding: 16px;
  padding-bottom: 42px;
}

.descriptionDate {
  margin: 0px;
  text-align: end;
  position: absolute;
  bottom: 16px;
  right: 16px;
}


.buttonContainer {
  width: 90%;
  margin: 10px auto;
}

.buttonGridContainer {
  width: 100%;
  display: flex;
}

.editButton {
 margin-bottom: 4px;
}

.removeButton {
  margin-left: 2px;
}

.downloadButton {
  width: 100%;
  /* margin-right: 2px; */
  text-decoration: none;
  
}

.commentInput{
  background-color: var(--offWhite);
  width: 100%;
  /* margin: 18px 5% 0px 5%; */
  padding: 10px;
  resize: none;
  border-radius: 10px;
  min-height: 100px;
}

.commentButton{
  width: 90%;
  margin: 0px 5% 18px;
}