.text {
    color: var(--textColour);
    font-size: 20px;
    font-weight: 600; 
    white-space: pre;
    text-align: center;
}
.yesNoCtn{
    background-color: var(--offWhite);
    width: 50%;
    min-width: 75%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    padding:20px 20px 20px;
    z-index: 2;
}

.background{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height:100%;
    background-color: rgba(120, 120, 120,.5);

}

.buttons {
    display: flex;
    width:100%;
    margin-top: 25px;
    /* grid-template-columns: 1fr 1fr; */
    column-gap: 10px;
}

