.page {
    background-color: #fff;
    /* z-index: 1; */
}

.pageDesktop {
    /* border-left: 1px dotted black; */
    overflow: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;
    max-width: 256px;
    height: 100vh;
    position: relative;
    top: 0px;
    right:0px;
    
}



/* scrollbar */
.pageDesktop::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.pageDesktop::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

.pageDesktop::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.pageDesktop::-webkit-scrollbar-thumb:window-inactive {
    background: rgb(255, 255, 255);
}