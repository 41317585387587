.buffer {
  /* display: block; */
  flex: 1;
  flex-grow: 0;
  flex-basis: 8vh;
  min-height: 46px;
  height: 8%;
  max-height: 60px;
  /* min-height: 8%; */
}

.iconWrapper {
  display: inline-block;
}

/* .bufferItem{
    height: 8vh;
} */
.ctn {
  min-height: 46px;
  height: 8%;
  max-height: 60px;
  width: 100%;
  /* border: 2px solid black; */
  position: fixed;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: var(--grayDark);
  /* dont think this does anything (below)*/
  /* color: var(--offWhite); */
  /* overflow: hidden; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.icon {
  height: auto;
  width: auto;
}
.selected {
  color: var(--offWhite);
  display: block;
}
.unSelected {
  color: var(--grayLight);
  display: block;
}
.link{
    position: relative;
}

.iconNotify {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: red;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
