.button {
    padding: 0px 0px;
    width: 100%;
    height: 40px;
    font-family: 'Montserrat', sans-serif;
    font-size: medium;
    font-weight: 500;
    border-style: solid;
    border-radius: 8px;
    border-width: 2px;
    transition: color 0.5s, background-color 0.5s, border-color 0.5s;
    /* text-decoration: none; */
    cursor: pointer;
  }

  .centerSpreadContent{
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .buttonSolid {
    color: var(--offWhite);
    background-color: var(--primary);
    border-color: var(--primary);
  }
  
  .buttonHollow {
    color: var(--primary);
    background-color: var(--primaryBg);
    border-color: var(--primary);
  }
  
  .buttonSolid:hover {
    color: var(--primary);
    background-color: var(--primaryBg);
    border-color: var(--primary);
  }
  
  .buttonHollow:hover {
    color: var(--offWhite);
    background-color: var(--primary);
    border-color: var(--primary);
  }

  .buttonFlag {
    color: var(--secondaryDark);
    background-color: var(--grayDark);
    border-color: var(--grayDark);
  }

  .buttonFlag:hover {
    color: var(--secondaryDark);
    background-color: var(--primaryDark);
    border-color: var(--secondaryDark);
  }