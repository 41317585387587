.loginCtn{
    width: fit-content;
    /* border: 1px rgb(114, 114, 114) solid; */
    border-radius: 20px;
    padding: 16px 32px;
    box-shadow: 0px 10px 50px rgb(114, 114, 114); 
    background-color: rgb(255, 255, 255);
}
.backgroundColour{
    background-color: rgb(234, 233, 233);
}

  .registerLinkText {
    display: inline;
    margin: 0px;
    text-align: center;
    font-style: italic;
    cursor: pointer;
  }
  .forgotPasswordLinkText {
    /* display: b; */
    margin: 0px;
    text-align: right;
    font-style: italic;
    cursor: pointer;
    color: #fa541c;
    width: 100%;
  }
  .forgotPasswordLink {
    display: block;
    width: 100%;
  }
  .labelCtn{
    width: 100%;
    margin: 4px 0px;
  }
  .forgetPasswordCtn{
    width: 100%;
  }