.removeColour{
    color: var(--secondaryDark);
}
.addColour{
    color: var(--primary);
}
.pageCtn {
    background-color: var(--primaryBg);
}
.peopleCardCtn{
    background-color: var(--offWhite);
    height:54px;
    display: grid;
    grid-template-columns: 50px 1fr 50px;
    border: 2px solid var(--primary);
    margin: 4px 0px;
    border-radius: 8px;
}

.fileTitle{
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
}

.search {
    margin-bottom: 10px;
}

.profileImg{
    padding: 5px;
    width: 100%;
    height:50px;
    border-radius: 8px;
    
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.clientName{
    margin-left:4px;
}


.inputCtn{
    width: 100%;
    display:grid;
    grid-template-columns: auto 54px;
  
}

.svgPosition {
    justify-self: center;
    align-self: center;
    height: 24px;
}


.ctn{
    width:90%;
    margin: 0px 5%;
}
