input {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
select {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.search {
  min-height: 40px;
  width: 100%;
  background-color: var(--offWhite);
  border-radius: 50px;
  padding-left: 20px;
  border: 2px solid var(--offWhite);
}

.search:focus {
  outline: none;
  border: 2px solid var(--offWhite);
  /* border: 2px solid var(--secondary); */
}

/* standard text input */
.textInput{
  height: 40px;
  width: 100%;
  /* background-color: var(--grayLight); */
  /* background-color: var(--offWhite); */
  background-color: var(--offWhite);
  border-radius: 10px;
  padding: 8px 10px;
  padding-left: 10px;
  border: 2px solid var(--primary);

  /* padding:8px; */
  display:block;
}

.textInput:focus{
  outline: none;
  border: 2px solid var(--secondary);
}