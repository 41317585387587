.filePlaceholder {
    height: 40vh;
    width: 100%;
    margin: 0px;
    border: 2px solid var(--primaryDark);
    background-color: var(--primary);
}

.videoContainer{
  width:100%;
  margin: 0px;
  height: auto;
}
.pdfContainer{
  width:100%;
  margin: 0px;
  min-height: 700px;
  box-sizing: border-box;
}
.noPDF {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5%;
}