.ctn {
  height: 50px;
  padding: 5px;
  background-color: #e6f4ff;
  color: #1677ff;
  /* border-bottom: 1px solid #8B0000; */
  border-radius: 7px;
  margin-left: 4px;
  margin-bottom: 2px;
}

.seen {
  background-color: #e2e2e2;
  color: rgb(104, 104, 104);
  /* border-bottom: 1px solid #ffffcc; */
}

.noteBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-right: 5px;
}

.noteText {
  margin: 0px 5px 0px 10px;
  height:40px;
  /* font-size: 0.8rem; */
  font-size: 12px;
}

.noteText > p {
  margin: 0px;
  display: inline;
}

.img {
  height: 32px;
  width:32px;
  border-radius: 7px;
  object-position: center;
  display: block;
}

.date {
  font-size: 0.7rem;
}

.timeStamp {
  margin: 0px;
  margin-right: 5px;
  text-align: right;
  white-space: nowrap;
}
