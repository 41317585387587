.background {
    background-color: var(--primaryBg);
}

.pageCtn {
    background-color: var(--primaryBg);
    width: 90%;
    margin: 0px 5%;
}


.infoBox {
    background-color: var(--primaryLight);
    border-radius: 10px;
    margin-top: 25px;
    margin-bottom: 20px;
    width: 90%;
    height: 100px;
    border: 1px solid var(--primary);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding:10px;
    position: relative;

}

.settingIcon {
    position: absolute;
    right: 20px;
    top: 20px;
}

.test {
    min-width: 35%;
    color: var(--textColour);
}

.img {
    object-fit: cover;
    margin-right: 10px;
    /* height: 100%; */
    /* width: auto; */
    width: 75px;
    height: 75px;
    border-radius: 10px;
}  

.name {
    color: var(--textColour);
    margin: 0px 0px 5px 0px;
    font-size: 1.2rem;
}

.info {
    color: var(--textColour);
    font-size: 12px;
    word-wrap:break-word;
    margin: 0px;
}

.email {
    color: var(--textColour);
    font-size: 12px;
    word-wrap:break-word;
    margin: 0px;
}

.notesBox {
    background-color: var(--grayDark);
    padding: 5px;
    width: 90%;
    color: var(--offWhite);
    border-bottom: none !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-top:25px;
}

.notesBottom {
    background-color: var(--offWhite);
    width: 90%;
    min-height:125px;
    margin-bottom: 5px;
    padding: 5px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: none !important;
    resize: none;
}

.textInput{
    width: 100%;
    height: 100%;
}

.button {
    width: 90%;
    margin: 5px 10px auto;
}

.spacer {
    margin-bottom: 10px;
}