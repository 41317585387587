.container {
  background-color: var(--primaryBg);
   
}

.body {
  margin: 0px 7.5%;
  display: flex;
  flex-direction: column;

}

.cnt1 {
  display: flex;
  /* margin: 18px; */
  /* display: none; */
}

.cnt2 {
  flex: 1;
  margin: 18px auto;
  margin-left: 15px;
  height: 100%;
}

.imageCtn {
  position: unset;
  display: unset;
}

.square {
  position: relative;
  max-width: 400px;
  max-height: 400px;
  margin: 18px auto;
  /* padding: -12px 0px 0px; */

}

@media (max-width: 767px) {
  .imageCtn{
    width: 100%;
    display: flex;
    align-items: center;
  }

  .square {
    max-width: 100%; /* Adjust as needed */
    height: 200px; /* Adjust as needed */
    margin:  auto; /* Adjust as needed */
    display: inline;
  }

  .cnt1 {
    display: inline;
    position: relative;
    margin:  10px;
  }

  .cnt2 {
    margin-left: 0px;
  }

  .image{
    margin: 10px auto;   
    position: relative;;
    max-width: 200px !important; 
    max-height: 200px !important;
  }
}

.square::after {
  content: "";
  display: block;
  /* padding-bottom: 100%; */
}

.image {
  position: relative;
  /* width: 100% !important; */
  max-height: 100%;
  object-fit: cover;
  /* object-position: 50% 50%; */
  border-radius: 5%;
}

.coverImage {
  object-fit: cover;
}

.coverImage::before {
  content: "" !important;
  display: block !important;
  padding-top: 100% !important;
  /* 1 / 2 */
}

.userInfo {
  height: auto;
  width: 85%;
  margin: 15px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.imageContainer {
  margin: 0px auto;
}

.profileImage {
  border-radius: 10px;
  height: 100px;
  width: 100px;
  object-fit: cover;
}

.profileName {
  margin: 0px auto;
  color: var(--textColour);
}

.profileAgeGender {
  color: var(--textColour);
  margin: 5px auto;
}

.contactInfo {
  /* background-color: var(--offWhite); */
  border: 2px solid var(--primaryDark);
  border-radius: 10px;
  height: auto;
  width: 85%;
  margin: 5px auto;
  padding: 10px;
  color: var(--textColour);
}

.infoHeading {
  font-size: 0.75rem;
  text-decoration: underline;
}

.phNumber {
  margin: 5px 0px;
  word-wrap: break-word;
}

.email {
  margin: 5px 0px;
  word-wrap: break-word;
}

.addressInfo {
  border: 2px solid var(--primaryDark);
  /* background-color: var(--offWhite); */
  border-radius: 10px;
  height: auto;
  width: 85%;
  margin: 10px auto;
  padding: 10px;
  color: var(--textColour);
}

.address {
  margin: 5px 0px;
}

.btn {
  /* width: 85%; */
  margin: 10px auto;
}

.activeInfo {
  height: auto;
  width: 85%;
  margin: 10px auto;
  display: flex;
}

.activeContainer {
  padding: 5% 0px;
  flex: 1;
  text-align: center;
}

.created,
.active {
  color: var(--grayDark);
  margin: 5px auto;
}

.text {
  color: var(--grayDark);
}