.icon {
    display:block;
}

.date{
    display: flex;
    justify-content: space-around;
    width:100%;
    align-items: center;
}
.buttons {
    display: grid;
    width:100%;
    margin-top: 20px;
    grid-template-columns: 1fr 1fr;
    column-gap: 6px;
    row-gap: 6px;
}
.slotsCtn {
    height: 150px;
    overflow-y: scroll;
}

.bookingTitle {
    text-align: center;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 10px;
}