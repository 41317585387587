.allFilesCtn{
    width: 90%;
    margin: 10px auto;
    /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px,1fr));
    grid-template-rows: 120px;
    column-gap: 5px;
    grid-gap: 5px; */
}

.pageCtn{
    background-color: var(--primaryBg);
    padding-bottom: 10px;
}

/* this is subtly different from the slide filectn, 
we could potentially make them the same?
would make it easier for making changes to the file appearence */
.fileCtn{
    /* background-color:var(--primary); */
    /* width: 100px; */
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 120px;
    margin: 0px auto;
    color: var(--textColour);
    padding: 5px;
}

.thumbnail {
    /* height: 86px; */
    /* height: 48px; */
    /* width:auto; */
    /* margin: auto; */
    /* padding: 5px; */
}

.name{
    margin: 0px;
    /* margin-bottom: 5px; */
    height:60px;
    text-align: center;
    font-size: 16px;
    padding: 0px 2px;
    overflow: hidden;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    
}

.upload {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  }

  .upload + label {
    display: inline-block;
    cursor: pointer;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.searchCtn{
    width: 90%;
    margin: 10px auto;
    display: flex;
}

.add{
    margin-left: 5px;
}


.filterCtn{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 90%;
    margin: 0px auto;
}

.filterText{
    font-size: 0.75rem;
    margin:0px 5px 0px 0px;
    display: inline;
    color:var(--textColour);
}

.filterRight{
    display: flex;
    justify-content: right;
    align-items: center;
}
.filterLeft{
    display: flex;
    justify-content: left;
    align-items: center;
}

.uploadCtn{
    background-color: var(--offWhite);
    width: 80%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    padding:16px 20px 20px;
    z-index: 2;
}

.uploadMargin{
    margin: 4px 0px;
}

.description {
    resize: none;
    height: 80px !important;
    font-family: Arial, Helvetica, sans-serif;
}

.uploadBtn{
    margin-top: 32px;
}

.uploadGrid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 4px;
}

.dropZone {
    width: 100%;
    height:40px;
    border: 2px dashed var(--secondaryDark);
    border-radius: 8px;
    color: var(--textColour);
    display: flex;
    justify-content: center;
}

.dropText {
    text-align: center;
    margin: auto 0px;
}


.background{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height:100%;
    background-color: rgba(120, 120, 120,.5);
}

.icon {
    margin-right: 5px;
}