.searchBar {
    padding: 5px 5%;
    margin: 0px 0px;
    background-color: var(--grayDark);
}

.pageCtn {
    background-color: var(--primaryBg);
     
}

.clientListCtn {
    padding: 0px 5%;
    background-color: var(--primaryLight);
}

