.cnt {
    /* background-color: var(--primaryBg); */
}
@media (max-width: 400px) {
    .box {
        background-color: white;
        border-radius: 8px;
        padding: 0px;
    }
    .finalStepsCheckBox{
        width: 300px;
        height: 30px;
        text-align: start;
        align-items: center;
    }
    .finalStepsCheck {
        margin-top: 12px;
        display: flex;
        align-items: center;
    }
}
.box{
    background-color: white;
    border-radius: 8px;
    padding: 10px;
}
.subGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
    row-gap: 24px;
    align-items: center;
    min-width: 80vw;
    /* min-width: 300px; */
}

@media (max-width: 768px) {
    .subGrid {
        grid-template-columns: 1fr;
    }
}

@media (min-width: 768px) {
    .subGrid {
        /* min-width: 500px; */
        min-width: 66vw;
    }
}

.subTitle {
    margin: 0px auto;
    margin-bottom: 0px !important;
    display: inline;
    text-align: center;
}

.subCard {
    height: 100%;
    padding: 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.nextButton {
    grid-column: 1 / span 2;
}

.subRadio {
    position: absolute;
    top: 6px;
    right: 2px;
}

.form {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: 5px auto;
}

.label {
    margin: 5px 5px 5px;
    display: inline-block;
    font-size: 1.2rem;
    font-weight: 500;
}

.labelNone {
    display: none !important;
}

.centreImage {
    display: flex;
    justify-content: center;
}

.imgCtn {
    width: 250px;
    height: 250px;
    background-color: white;
    border-radius: 7px;
}

.imageArea {
    position: relative;
    width: 100%;
    padding-bottom: 0%; /* Set the same value as the width */
}


.defaultImage {
    width: 100%;
    height: 100%;
    border: 1px dashed #d9d9d9;
    border-radius: 7px;
    transition: border-color 0.3s;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.02);
}

.defaultImage:hover {
    border-color: #1677ff;
}

.previewImg {
    width: 100%;
    height: 100%;
    border: 1px dashed #d9d9d9;
    border-radius: 7px;
    transition: border-color 0.3s;
    cursor: pointer;
    object-fit: cover;
    display: none;
    box-sizing: border-box;
}

.showImg {
    width: 100%;
    height: 100%;
    border: 1px dashed #d9d9d9;
    border-radius: 7px;
    transition: border-color 0.3s;
    cursor: pointer;
    object-fit: cover;
    box-sizing: border-box; 
    /* position: relative;  */
    /* vertical-align: bottom;  */
}

.previewImg:hover {
    border-color: #1677ff;
}

.topBox {
    margin-top: 20px;
}



.upload {
    text-align: center;
    margin: auto !important;
    display: block;
    /* position: absolute; */
    /* bottom: 15px; */
    /* left:92px; */
}

.headerTxt {
    text-align: center;
    font-style: italic;

}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    align-items: center;
}

.middleBox {
    margin-top: 15px;

}

.margin {
    margin-bottom: 2px;
}

.button {
    margin-top: 20px;
    margin-bottom: 20px;
}

.passLabel {
    font-size: 14px;
    display: block;
}

/* FINAL STEPS */

.finalStepsCnt{
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}

.finalStepsCnt p {
    margin: 0px;
    text-align: center;
}

.finalStepsCheck {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* text-align: start; */
}

.finalStepsCheck p{
    margin: 0px 0px 5px 0px;

}

.finalStepsCheckBox {
    width: 325px;
    height: 30px;
    text-align: start;
    align-items: center;
}