.container {
    background-color: var(--primaryBg);
}

.passwordContainer {
    margin: 32px auto;
    display: flex;
    flex-direction: column;
    width: 85%;
    max-width: 350px;
}

.input{
    margin: 5px 0px;
  }
  

  
.button {
    margin-top: 25px;
}