.container {
  background-color: var(--primaryBg);
}

.link {
  display: block;
  text-decoration: none;
  margin-left: 7.5%;
  margin-right: 7.5%;
}

.arrow {
  /* margin-right: 3%; */
  height: 60%;
  width: auto;
}

.password {
  /* margin-left: 3%; */
  height: 80%;
  width: auto;
}

.person {
  /* margin-left: 3%; */
  height: 80%;
  width: auto;
}

.feedbackButton {
  /* display: block; */
  margin-left: 7.5%;
  margin-right: 7.5%;
  width: 85%;
}
