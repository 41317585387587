.eventCtn {
  position: absolute;
  /* border-radius: 8px; */
  /* background-color: #FED5CF; */
  /* border: 1px solid #F1B598; */
  cursor: pointer;
  overflow: hidden;
  padding: 5px;  /* Adjusted padding to apply to all sides */
  display: flex;
  justify-content: center; 
  align-items: center;
  /* z-index: 2; */
}

.eventAvailable{
  background-color: lightcyan;
  /* opacity: 80%; */
  border-radius: 8px;
  border: 1px solid lightsteelblue;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
}
.eventNotAvailable{
  background-color: #FED5CF;
  /* background-color: #BEDAE3; */
  /* border-radius: 8px; */
  /* border: 1px dotted purple; */
  opacity: 50%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='60' height='60' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%231677ff' fill-opacity='0.15'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

/* Media query for screens with a max width of 600px (you can adjust this value) */
@media (max-width: 600px) {
  .eventCtn {
      justify-content: flex-start;  /* Align content to the left */
  }
}
  
  .eventText{
    font-size: 12px;
    
  }