.container {
  background-color: var(--primaryBg);
  padding-bottom: 10px;
}

.link {
  display: block;
  text-decoration: none;
  margin-left: 7.5%;
  margin-right: 7.5%;
}

.arrow {
  height: 75%;
  width: 27.5px;
}

.person {
  height: 75%;
  width: 27.5px;
}

.feedbackButton {
  /* display: block; */
  margin-left: 7.5%;
  margin-right: 7.5%;
  width: 85%;
}
