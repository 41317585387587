.pageCtn{
    background-color: var(--primaryBg); 
}

.gButton {
    color: var(--secondary);
    background-color: var(--primary);
    border-color: var(--primary);
    margin-top: 15px;
}
.gButton:hover {
    color: var(--secondary);
    background-color: var(--primaryBg);
    border-color: var(--secondary);
}

.ctn{
    font-family: 'Courier New', 'Lucinda Sans Typewriter', monospace;
    font-size: 1.25rem;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
}

.svgCnt {
    margin-right:10px;
    display: flex;
}

.svg {
    justify-content: center;
    cursor: pointer;
    color: var(--textColour);
}


.codeCtn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--textColour);
    background-color: var(--primaryLight);
    border-color: var(--primary);

    width: 100%;
    height: 40px;
    padding-left: 10px;
    border-style: solid;
    border-radius: 8px;
    border-width: 2px; 
    margin-bottom: 5px;
}