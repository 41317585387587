:root {
  --primaryDark: #294a5d;
  --primary: #4484ce;
  --primaryLight: #9fcdff;
  --secondaryDark: #f79256;
  --secondary: #ffba5e;
  --grayDark: #4f4f4f;
  --grayLight: #9b9b9b;
  --primaryBg: #d8d2cb;
  --offWhite: #ecf0f1;
  --textColour: #131930;
}

.rootContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
}

@media (max-width: 768px) {

  /* Styles for smaller screens go here */
  .rootContainer {
    flex-direction: column;
  }
}

.crosses {
  background-color: #e9e9e9;
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='60' height='60' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%231677ff' fill-opacity='0.15'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"); */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='60' height='60' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%231677ff' fill-opacity='0.15'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.horizontalCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.verticalCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center */
}

.verticalRowCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/* LOGIN */
@media (max-width: 500px) {
  .loginLeftContainer {
    display: none;
  }

  .loginContainer {
    grid-template-columns: 1fr !important;
  }
}

.fillHeight {
  flex: 1;
}


.loginNotice {
  color: var(--grayDark);
  margin: 16px 10px;
  text-align: center;
  /* or none, and make the right container padding be 16px */
}

.loginLabel {
  color: var(--offWhite);
}

.loginButton {
  width: 100%;
}

.loginSubmit {
  color: var(--secondary);
  background-color: var(--primary);
  border-color: var(--primary);
  margin-top: 20px;
}

.loginSubmit:hover {
  color: var(--primary);
  background-color: var(--offWhite);
  border-color: var(--primary);
}

.loginInput {
  width: 250px;
  padding: 12px 20px;
  background-color: var(--grayDark);
  color: var(--offWhite);
  border: 2px solid var(--offWhite);
  border-radius: 4px;
}

.loginInput:focus {
  outline: none;
  border: 2px solid var(--secondary);
}

.loginInput::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--primaryBg);
  opacity: 1;
  /* Firefox */
}