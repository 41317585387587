.commentBox {
    display: grid;
    grid-template-columns: minmax(60px, 1fr) 20fr;
    height: auto;
    width: 100%;
    /* margin: 5px auto;
    border-radius: 10px;
    background-color: var(--secondaryDark);
    color: var(--textColour); */
}

.imageContainter{
    padding: 5px ;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.image {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    object-fit: cover;
}

.comment{
    margin: 0px;
    padding: 10px 10px;
}

.dateTime{
    display: inline-block;
    font-size: .75rem;
    margin:0px;
    margin-bottom: 5px;
    padding-left: 10px;
    text-align: end;
}