.cnt {
    background-color: var(--primaryBg);
}

.bookingMadeCnt {
    /* padding-top: 30px; */
    /* width: 90%; */
    /* margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center; */
    /* max-width: 400px; */
}

.page {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    margin: auto;
    text-align: center;
    align-items: center;
    max-width: 400px;
}

.bookingFailedCtn{

}

.generalCtn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 90vh;
}

.text {
 margin-top: 0px;
 margin-bottom: 24px;
} 

.closeText {
    margin-bottom: 6px;
}