.searchBar {
  padding: 12px 5%;
  margin: 0px 0px;
  background-color: #1677ff;
  display: flex;
  align-items: center;
}

.pageCtn {
  background-color: var(--primaryBg);
  
}
.bodyCtn{
  padding: 0px 6px;
}

.searchIcon {
  margin-left: 5px;
  height: 30px;
  width: 30px;
  display: block;
  color: var(--primaryDark);
}

.packageCtn {
  width: 100%;
  height: 60px;
  background-color: var(--primaryLight);
  border: 1px solid var(--grayDark);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1px;
  box-shadow: 0 8px 6px -1px rgba(0, 0, 0, 0.4);
  padding: 0px 10%;
  color: var(--textColour);
  /* box-shadow: [horizontal offset] [vertical offset] [blur radius] [optional spread radius] [color];   */
}


