.pageCtn {
    display: flex;
    width: 100%;
}

.ctn {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.labelCtn {
    width: 100%;
    margin: 4px 0px;
  }

  .forgotPageCtn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }