.title {
    background-color:var(--grayDark);
    font-size: 1.5rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    display: flex;
    justify-content: space-around;
    width:100%;
    height: 50px;
    position: sticky;
    top: -1px;
    z-index: 2;
}

.titleText {
    margin: auto 0px;
    color: var(--primaryBg);
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}

.backButtonCtn {
   display: flex;
   align-items: center; 
   padding: 0px 5px;
   /* position: absolute;
   left:16px;
   top:16px; */
}

.backButton {
   color: var(--primaryBg);
   cursor: pointer;
   margin:0px 5px;
}



.hiddenDiv {
    width: 33.5px;
    margin:0px 5px;
}