.container {
    background-color: var(--primaryBg);
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #e9e9e9;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='60' height='60' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%231677ff' fill-opacity='0.15'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

/* .container {
    background-color: var(--primaryBg);
    display: flex;
    align-items: center;
    flex-direction: column;
  
} */

.welcome {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 4rem 0px 1rem 0px;
    font-weight: 400;
    font-size: 2.1rem;
    background-color: #e9e9e9;
}

.btn {
    width: 85%;
}

.clock {
    margin-left: 3%;
    height: 80%;
    width: auto;
}

.arrow {
    margin-right: 3%;
    height: 60%;
    width: auto;
}