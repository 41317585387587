.pageCtn{
    background-color: var(--primaryBg);
}

.ctn{
    max-width: 526px;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
}

.profileImage {
    border-radius: 10px;
    height:100px;
    width:100px;
    object-fit: cover;
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.photoGrid {
    display: grid;
    grid-template-columns: 1fr 2fr;
}

.upload {   
    width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.upload + label {
    display: inline-block;
    cursor: pointer;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputMargin{
    margin-bottom: 5px;
}


.save{
    margin: 24px 0px;
}